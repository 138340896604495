<script setup lang="ts">
import type { Reference } from '~/types/api'

const props = defineProps<{
  reference: Reference
}>()

const localePath = useLocalePath()

const { getResourceUrl } = useResources()

const image = computed(() => props.reference?.thumbnailImage)

const subtitle = computed(() => {
  const values = [
    props.reference.year,
    props.reference.location,
    props.reference.country?.title,
  ]
  return values.filter((val) => !!val).join(' — ')
})
</script>

<template>
  <div class="group">
    <NuxtLink
      :to="
        localePath({
          name: 'reference-slug',
          params: { slug: reference.urlSlug },
        })
      "
      class="relative m-0 block h-full overflow-hidden"
    >
      <div>
        <div
          class="absolute z-10 flex h-full w-full flex-col justify-between bg-black bg-opacity-30 p-8 text-white transition-colors duration-300 group-hover:bg-transparent"
        >
          <div class="group-hover:drop-shadow-lg">
            <h2 class="font-pragmatica-ext mb-4 text-4xl font-bold">
              {{ reference.title }}
            </h2>
            <h3 class="text-xl">
              {{ subtitle }}
            </h3>
          </div>
        </div>
        <div
          class="before:content[''] relative z-0 block h-full w-full overflow-hidden bg-neutral-50 p-0 before:block before:pt-[75%]"
        >
          <NuxtImg
            alt=""
            title=""
            class="absolute bottom-0 left-0 top-0 z-0 h-full w-full border-0 transition-transform duration-300 group-hover:scale-105"
            loading="lazy"
            sizes="300px sm:500px"
            :src="getResourceUrl(props.reference.thumbnailImage.url)"
          />
        </div>
      </div>
    </NuxtLink>
  </div>
</template>
